@import url(https://unpkg.com/leaflet@1.6.0/dist/leaflet.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.main-layout {
  height: 100%;
}
.dashboard-coming-soon-icon {
  font-size: 4em;
  color: #f5aa3b;
}
.sso-coming-soon-icon {
  font-size: 4em;
  color: #f5aa3b;
}
.notifications-coming-soon-icon {
  font-size: 4em;
  color: #f5aa3b;
}
#prayertimes-main-map {
  width: 300px;
  height: 300px;
}
.info-card {
  display: flex;
  width: 300px;
  margin: 16px;
}

.info-card .title {
  font-size: 2em;
  color: #AAAAAA;
}

.info-card .value {
  font-size: 3em;
  text-align: center;
}

.info-card .icon {
  vertical-align: middle;
  font-size: 56px;
}



#prayertimes-detail-map {
  height: 500px;
}


.ramadan-coming-soon-icon {
  font-size: 4em;
  color: #f5aa3b;
}
.quran-coming-soon-icon {
  font-size: 4em;
  color: #f5aa3b;
}




#prayertimes-detail-map {
  height: 500px;
}

.auth-layout {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100vh;
  background: #f8f8f8;
  align-items: center;
  justify-content: center;
  
}

.auth-layout .outer {
  display: flex;
  max-width: 400px;
  width: 400px;
}

.auth-layout .inner {
  display: flex;
  flex: 1 1;
  flex-direction: column;
	background-color: #fff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.auth-layout .inner-header {
	background: #1d411d;
	width: 100%;
	padding: 15px 0;
	text-align: center;
}

.auth-layout .inner-header img {
  max-height: 100px;
}

.auth-layout .content {
	padding: 15px 30px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.main-layout {
  height: 100%;
}
.logo {
  height: 100px;
  margin: 1em;
  background-color: #000000;
  display: flex;
  justify-items: center;
  padding: 16px;
}

.menu-section-icon {
  margin-right: 10px;
}

.collapsed-menu .title {
  display: none;
}

.full-menu .title {
  display: inline-block;
}
.auth-layout {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100vh;
  background: #f8f8f8;
  align-items: center;
  justify-content: center;
  
}

.auth-layout .outer {
  display: flex;
  max-width: 400px;
  width: 400px;
}

.auth-layout .inner {
  display: flex;
  flex: 1 1;
  flex-direction: column;
	background-color: #fff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.auth-layout .inner-header {
	background: #1d411d;
	width: 100%;
	padding: 15px 0;
	text-align: center;
}

.auth-layout .inner-header img {
  max-height: 100px;
}

.auth-layout .content {
	padding: 15px 30px;
}

.auth-layout .content .spin {
  min-height: 140px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

