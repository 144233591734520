.logo {
  height: 100px;
  margin: 1em;
  background-color: #000000;
  display: flex;
  justify-items: center;
  padding: 16px;
}

.menu-section-icon {
  margin-right: 10px;
}

.collapsed-menu .title {
  display: none;
}

.full-menu .title {
  display: inline-block;
}