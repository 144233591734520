.info-card {
  display: flex;
  width: 300px;
  margin: 16px;
}

.info-card .title {
  font-size: 2em;
  color: #AAAAAA;
}

.info-card .value {
  font-size: 3em;
  text-align: center;
}

.info-card .icon {
  vertical-align: middle;
  font-size: 56px;
}
