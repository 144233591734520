.auth-layout {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  background: #f8f8f8;
  align-items: center;
  justify-content: center;
  
}

.auth-layout .outer {
  display: flex;
  max-width: 400px;
  width: 400px;
}

.auth-layout .inner {
  display: flex;
  flex: 1;
  flex-direction: column;
	background-color: #fff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.auth-layout .inner-header {
	background: #1d411d;
	width: 100%;
	padding: 15px 0;
	text-align: center;
}

.auth-layout .inner-header img {
  max-height: 100px;
}

.auth-layout .content {
	padding: 15px 30px;
}

.auth-layout .content .spin {
  min-height: 140px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
